import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "react-responsive-modal/styles.css";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import RequestConfirmModal from 'components/General/RequestConfirmModal';
import { ADMIN, OPERATOR, USER, RESELLER } from 'libs/roles';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report'
import { actions as authActions } from 'redux/modules/Auth'
import { Field, reduxForm } from 'redux-form';
import VirtualizedSelectFormComponent from 'components/redux-form/VirtualizedSelectFormComponent';
import { min } from 'lodash';
import { toast, Toaster } from 'react-hot-toast';



moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport: state.report
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}


class BackofficeEditAdminUser extends Component {

  static propTypes = {
    userId: PropTypes.number,
    onSaveAdminUser: PropTypes.func.isRequired,
    onDeleteAdminUser: PropTypes.func.isRequired,
    onCancelEditAdminUser: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      userId: null,
      user: this.getNullAdminUser(),
      checkErrors: null,
      sendErrors: null,
      showConfirmDeleteArea: false,
      bkpBdg: null
    }

    this.saveAdminUser = this.saveAdminUser.bind(this)
    this.checkFields = this.checkFields.bind(this)
    this.getNullAdminUser = this.getNullAdminUser.bind(this)
    this.deleteAdminUser = this.deleteAdminUser.bind(this)
    this.getUsersToSelect = this.getUsersToSelect.bind(this)
    //this.saveBudget = this.saveBudget.bind(this)
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getNullAdminUser() {
    var nullUser = { id: null, firstName: '', lastName: '', email: '', role: USER, budget: null, bkpBdg: null }

    return nullUser
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({
      userId: null,
      user: this.getNullAdminUser(),
      checkErrors: null,
      sendErrors: null,
      showConfirmDeleteArea: false,
      bkpBdg: this.state.user.budget
    })

  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount() {
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  async componentDidUpdate(prevProps) {
    console.log('HHH_111');
    
    if (prevProps.userId !== this.props.userId) {
      this.stateInit();
      this.fetchData();
    }
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {

    var userId = null

    if (this.props.userId != null) {
      userId = this.props.userId
    }
    else if (this.state.userId != null) {
      userId = this.state.userId
    }
    else {
      this.setState({ user: this.getNullAdminUser() })
    }

    if (userId != null) {
      var user = await SostaOnlineApiService.fetchUser(userId)

      if (user != null && user.data != null) {
        this.setState({ user: user.data, bkpBdg: user.data.budget })
      }
      else {
        this.setState({ user: this.getNullAdminUser() })
      }
    }

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []

    if (this.state.user.id == null) {
      errors.push("Nessun utente selezionato")
    }

    if (this.state.user.role == null) { //|| this.state.user.role == USER) {
      errors.push("Ruolo non Specificato")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  async saveAdminUser() {
    var errors = this.checkFields();
    this.setState({ checkErrors: errors });

    if (errors != null && errors.length > 0) {
      return;
    }
    //console.log('saveAdminUser: role - ' , this.state.user.role , ' budget - ' , this.state.user.budget)
    try {
      await SostaOnlineApiService.updateUserRole(this.state.user.id, this.state.user.role, this.state.user.budget);
      if (this.props.onSaveAdminUser != null) {
        this.props.onSaveAdminUser(this.state.user.id);
      }
    } catch (error) {
      console.log("ERROR", error);
      var errorMsg = error != null && error.error != null ? error.error : "Errore durante Il salvataggio, verificare i dati!";
      this.setState({ sendErrors: errorMsg });
    }
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async deleteAdminUser() {

    var user = this.state.user

    if (user != null && user.id != null) {

      user.role = USER

      SostaOnlineApiService.updateUserRole(this.state.user.id, USER)
        .then(async (result) => {

          if (this.props.onDeleteAdminUser != null) {
            await this.props.onDeleteAdminUser()
          }

          await this.setState({ user: this.getNullAdminUser() })
        })
        .catch(error => {
          console.log("ERROR", error)
          var errorMsg = error != null && error.error != null ? error.error : "Errore durante Il salvataggio, verificare i dati!"
          this.setState({ sendErrors: errorMsg })
        })

      this.setState({ showConfirmDeleteArea: false })


    }

  }


  /*
   * --------------------------------------------------------------
   * 
   */
  onCancelEditAdminUser() {

    this.stateInit()
    this.fetchData()

    if (this.props.onCancelEditAdminUser != null)
      this.props.onCancelEditAdminUser()
  }

  /**
   * --------------------------------------------------------------
   */
  getUsersToSelect() {
    var users = []

    if (this.props.users != null) {
      users = this.props.users.filter(u => u.role == USER).map(user => {
        return {
          label: user.firstName + ' ' + user.lastName + ' ' + user.email, // + ' ( Ruolo: ' + user.role + ' - Budget: ' + user.budget + ' )',
          value: user.id
        }
      })
    }

    return users;
  }

  /**
   * --------------------------------------------------------------
   * @param {*} id 
   */
  async setUser(id) {
    await this.setState({ userId: id })
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} id 
   */
  async setRole(role, budget) {
    var user = this.state.user
    user.role = role
    user.budget = budget
    this.setState({ user: user })
  }

  //    /**
  //   * --------------------------------------------------------------
  //   */

  convertToNumber(theValue) {
    let stringValue;
    if (theValue === null) {
      stringValue = "null";
    } else if (theValue === -1) {
      stringValue = "-1";
    } else {
      // Formatta il numero in una stringa con la formattazione desiderata
      stringValue = theValue.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return stringValue;
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {

    const {
      userId
    } = this.props

    const {
      sendErrors,
      checkErrors,
      user,
      showConfirmDeleteArea,
      bkpBdg
    } = this.state

    const valore = this.convertToNumber(user.budget)
    //console.log("AREA VALUES",user)
    //console.log ( 'BackofficeEditAdminUser props:', this.props)

    if (this.props.auth.user.role != ADMIN) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
        <Toaster position="top-center" reverseOrder={false} />
        {user == null &&
          <div className="col-md-12">
            <div className="row">
              <div className="text-center" style={{ margin: "1em" }}>
                <div className="row"><h2>Caricamento in corso...</h2></div>
                <div className="row" style={{ padding: "2em", height: "40em" }}>
                  <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
                </div>
              </div>
            </div>
          </div>
        }

        {user != null &&
          <div className="col-md-12">
            <div className="app-modal-body">

              {userId == null &&
                <div className="row">
                  <div className="col-xs-12" style={{ padding: "1em", paddingTop: "0.5em", paddingBottom: "1em" }}>
                    <Field
                      style={{ maxWidth: "100%" }}
                      name="userId"
                      options={this.getUsersToSelect()}
                      component={VirtualizedSelectFormComponent}
                      onChange={value => { this.setUser(value) }}
                      placeholder={<div><FontAwesomeIcon size="1x" icon={solidIcons.faFilter} />&nbsp;&nbsp;Filtra per utente...</div>}
                      required />
                  </div>
                </div>
              }

              {user.id != null &&
                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-xs-12"><hr /></div>
                  </div>

                  <div className="row">
                    <div className="col-xs-3"><h5><strong>Id:</strong></h5></div>
                    <div className="col-xs-9"><strong>{user.id}</strong></div>
                  </div>


                  <div className="row" >
                    <div className="col-xs-3"><h5 ><strong>Nome:</strong></h5></div>
                    <div className="col-xs-9"><strong>{user.firstName}</strong></div>
                  </div>

                  <div className="row" >
                    <div className="col-xs-3"><h5 ><strong>Cognome:</strong></h5></div>
                    <div className="col-xs-9"><h5 ><strong>{user.lastName}</strong></h5 ></div>
                  </div>

                  <div className="row" >
                    <div className="col-xs-3"><h5 ><strong>Email:</strong></h5></div>
                    <div className="col-xs-9"><h5 ><strong>{user.email}</strong></h5 ></div>
                  </div>

                  <div className="row" >
                    <div className="col-xs-3">
                      <h5 >
                        <strong>Ruolo:</strong>
                      </h5 >
                    </div>
                    <div className="col-xs-9">
                      <h5 >
                        <strong>
                          <select
                            name="role"
                            className="form-control"
                            onChange={e => { this.setRole(e.target.value, this.state.user.budget) }}
                            value={user.role}
                            required >
                            <option value={USER}>{USER.toUpperCase()}</option>
                            <option value={ADMIN}>{ADMIN.toUpperCase()}</option>
                            <option value={OPERATOR}>{OPERATOR.toUpperCase()}</option>
                            <option value={RESELLER}>{RESELLER.toUpperCase()}</option>
                          </select>
                        </strong>
                      </h5 >
                    </div>
                  </div>
                  {/* SEZIONE BUDGET RESELLER */}
                  {user.role === RESELLER &&
                    <div>
                      {/* se si imposta un importo di budget per il reseller non viene mostrata questa legenda */}
                      {user.budget === 0 || ((user.budget !== null || user.budget !== -1)/* && !(user.budget > 0)*/) ?
                        <div className="row">
                          <div className="col-xs-3"><h5><strong>Plafond Budget</strong></h5></div>
                          <div className="col-xs-9">
                            <strong>
                              <div>
                                <span className="small"><strong>Il plafond del budget Rivenditore può essere;</strong></span>
                                <br />
                                <span className="small">· vuoto ( null ) se si intende bloccarlo oppure in seguito degradarlo a user;</span>
                                <br />
                                <span className="small">· infinito ( -1 ) senza limite di budget;</span>
                                <br />
                                <span className="small">· maggiore di zero ( &gt;0 - es. 1.000 ) se si vuole applicare un limite di plafond.</span>
                                <br />
                                <span className="small">Il plafond del budget non può mai essere zero.</span>
                                <br />
                                <span className="small">&nbsp;</span>
                              </div>
                            </strong>
                          </div>
                        </div>
                        :
                        <div></div>
                      }
                      <div className="row" >
                        <div className="col-xs-3">
                          {user.budget === null || user.budget === '' ? <span style={{ color: 'orange' }}>Budget nullo</span> : user.budget === "-1" || user.budget > 0 ? <span style={{ color: 'green' }}>Budget impostato</span> : <span style={{ color: 'red' }}>Budget bloccato</span>}
                        </div>
                        <div className="col-xs-9">
                          <Field
                            //key={`${Math.floor((Math.random() * 1000))}-min`}
                            //defaultValue={valore}
                            value={valore}
                            name="total"
                            component="input"
                            //type="number"
                            className="form-control"
                            placeholder={valore} //"Inserire un nuovo budget"
                            onChangeCapture={e => {
                              const inputValue = e.target.value === 0 || e.target.value === '' ? null : e.target.value == 'null' ? null : parseFloat(e.target.value); // Converte il valore in numero
                              if ((inputValue > 0 || inputValue === -1 || inputValue === null)) {
                                const newUser = { ...user, budget: inputValue };
                                this.setState({ user: newUser });
                                //this.props.change('total', user.budget); // a cosa serviva?
                              }
                            }} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                          {bkpBdg == user.budget ?
                            <span className="small">
                              Attualmente il plafond del budget per <strong>{user.firstName + ' ' + user.lastName}</strong> è impostato a <strong>{this.convertToNumber(bkpBdg)}</strong>.
                            </span>
                            :
                            <span className="small">
                              Il plafond del budget per <strong>{user.firstName + ' ' + user.lastName}</strong> era <strong>{this.convertToNumber(bkpBdg)}</strong>.
                            </span>
                          }
                          {bkpBdg !== user.budget ?
                            <span className="small"><br />Premere &quot;Salva&quot; per impostare il nuovo plafond del budget a <strong>{this.convertToNumber(user.budget)}</strong>.</span>
                            :
                            <span className="small"></span>
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12"><hr /></div>
                      </div>
                    </div>
                  }


                  <div className="row" >
                    {sendErrors &&
                      <h4 className="col-xs-12 text-danger text-center" style={{ marginBottom: '15px', marginTop: '10px' }}>
                        {sendErrors}
                      </h4>
                    }
                    {checkErrors && checkErrors.length > 0 &&
                      <h4 className="col-xs-12 text-danger text-center" style={{ marginBottom: '15px', marginTop: '10px' }}>
                        {checkErrors.map((error, index) => { return (<div key={index}>{error}</div>) })}
                      </h4>
                    }
                  </div>

                  {(userId != null || user.id != null) &&
                    <div className="row" >
                      <div className="col-xs-12 text-center" style={{ marginBottom: '15px', marginTop: '10px' }}>
                        <Button className="btn alertBtn" onClick={() => { this.onCancelEditAdminUser() }}>Annulla</Button>{' '}
                        <Button className="btn mainBtn" onClick={() => { this.saveAdminUser(); }} >Salva</Button>
                        {userId &&
                          <Button className="btn alertBtn" onClick={() => { this.setState({ showConfirmDeleteArea: true }) }}><FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina</Button>
                        }
                      </div>
                    </div>
                  }

                </div>
              }

            </div>
          </div>
        }

        <RequestConfirmModal
          requestStyle={2}
          requestTitle="Conferma Eliminazione del Ruolo"
          requestSubTitle="ATTENZIONE"
          requestMsg={"Proseguendo con l'operazione si eliminerà il ruolo " + user.role + " per l'utente " + user.firstName + " " + user.lastName + ".<br>Si vuole procedere?"}
          show={showConfirmDeleteArea}
          onConfirm={this.deleteAdminUser}
          onRefuse={() => { this.setState({ showConfirmDeleteArea: false }) }}
        />


      </React.Fragment >
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'BackofficeEditAdminUser' })(BackofficeEditAdminUser))
